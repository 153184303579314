/**************  
VARIABLES
***************/
.site-header .site-branding a {
  background: url("/logo-white.png");
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.site-header .site-branding a img {
  opacity: 0;
}
.site-header .burger-section .hamburger .hamburger-box .hamburger-inner {
  background: #fff;
}
.site-header .burger-section .hamburger .hamburger-box .hamburger-inner:before, .site-header .burger-section .hamburger .hamburger-box .hamburger-inner:after {
  background: #fff;
}
.site-header.sticky .site-branding a {
  background: none;
}
.site-header.sticky .site-branding a img {
  opacity: 1;
}
.site-header.sticky .burger-section .hamburger .hamburger-box .hamburger-inner {
  background: #0F2D51;
}
.site-header.sticky .burger-section .hamburger .hamburger-box .hamburger-inner:before, .site-header.sticky .burger-section .hamburger .hamburger-box .hamburger-inner:after {
  background: #0F2D51;
}

.home.section-one.grid-container.fluid {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 0;
}
.home.section-one.grid-container.fluid .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-one.grid-container.fluid .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(10, 31, 65, 0.4);
}
.home.section-one.grid-container.fluid .grid-x {
  position: relative;
  min-height: 100vh;
}
.home.section-one.grid-container.fluid .grid-x .txt {
  padding: 10rem;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-one.grid-container.fluid .grid-x .txt {
    padding: 3rem;
  }
}
.home.section-one.grid-container.fluid .grid-x .txt h1, .home.section-one.grid-container.fluid .grid-x .txt h2, .home.section-one.grid-container.fluid .grid-x .txt h3, .home.section-one.grid-container.fluid .grid-x .txt h4, .home.section-one.grid-container.fluid .grid-x .txt p {
  color: #fff;
}
.home.section-one.grid-container.fluid .grid-x .txt h1 {
  margin-bottom: 1rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-one.grid-container.fluid .grid-x .txt h1 {
    font-size: 3rem;
  }
}
.home.section-one.grid-container.fluid .grid-x #cta {
  position: absolute;
  bottom: 5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.home.section-one.grid-container.fluid .grid-x #cta:hover {
  cursor: pointer;
}
.home.section-one.grid-container.fluid .grid-x #cta svg {
  max-height: 8rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-one.grid-container.fluid .grid-x #cta svg {
    max-height: 5rem;
  }
}
.home.section-two.grid-container.fluid {
  z-index: 0;
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-two.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.home.section-two.grid-container.fluid .cell .item {
  z-index: 1;
  position: relative;
}
.home.section-two.grid-container.fluid .cell .item a {
  width: 100%;
  height: 50rem;
  position: relative;
  display: inline-block;
  overflow: hidden;
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-two.grid-container.fluid .cell .item a {
    height: 40rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid .cell .item a {
    height: 30rem;
  }
}
.home.section-two.grid-container.fluid .cell .item a img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-two.grid-container.fluid .cell .item a .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
  opacity: 0;
}
.home.section-two.grid-container.fluid .cell .item a .txt {
  opacity: 0;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-two.grid-container.fluid .cell .item a .txt button {
  cursor: pointer;
}
.home.section-two.grid-container.fluid .cell .item a:hover .txt, .home.section-two.grid-container.fluid .cell .item a:hover .mask {
  opacity: 1;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-two.grid-container.fluid .cell.one {
    margin-bottom: 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid .cell.one {
    margin-bottom: 3rem;
  }
}
.home.section-two.grid-container.fluid .cell.one h2 {
  margin: 0;
  line-height: 0.75;
  z-index: 2;
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .cell.one h2 {
    text-align: left;
    font-size: 9rem;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-two.grid-container.fluid .cell.one h2 {
    font-size: 9rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-two.grid-container.fluid .cell.one h2 {
    font-size: 9rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid .cell.one h2 {
    font-size: 5rem;
  }
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .cell.one .item {
    padding-left: 10rem;
    padding-right: 2.5rem;
  }
}
.home.section-two.grid-container.fluid .cell.two h2 {
  margin: 0;
  line-height: 0.75;
  z-index: 2;
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .cell.two h2 {
    text-align: right;
    font-size: 9rem;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-two.grid-container.fluid .cell.two h2 {
    font-size: 9rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-two.grid-container.fluid .cell.two h2 {
    font-size: 9rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid .cell.two h2 {
    font-size: 5rem;
  }
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .cell.two .item {
    padding-right: 10rem;
    padding-left: 2.5rem;
  }
}
.home.section-three.grid-container.fluid {
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-three.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.home.section-three.grid-container.fluid .background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-three.grid-container.fluid .txt {
  position: relative;
}
.home.section-three.grid-container.fluid .txt .content {
  background: rgba(15, 45, 81, 0.7);
  padding: 5rem;
}
@media print, screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .txt .content {
    padding: 5rem 15rem 15rem 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-three.grid-container.fluid .txt .content {
    padding: 2rem;
  }
}
.home.section-three.grid-container.fluid .txt .content .separator {
  width: 30rem;
  background: #fff;
  height: 1px;
  margin: 5rem 0;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-three.grid-container.fluid .txt .content .separator {
    width: 10rem;
    margin: 3rem 0;
  }
}
.home.section-three.grid-container.fluid .txt .content h1, .home.section-three.grid-container.fluid .txt .content h2, .home.section-three.grid-container.fluid .txt .content h3, .home.section-three.grid-container.fluid .txt .content h4, .home.section-three.grid-container.fluid .txt .content h5, .home.section-three.grid-container.fluid .txt .content a, .home.section-three.grid-container.fluid .txt .content p {
  color: #fff;
}
.home.section-three.grid-container.fluid .txt .content .button1 {
  background: #fff;
  color: #0F2D51;
}
.home.section-three.grid-container.fluid .txt .content .button1:hover {
  border-color: #fff;
  color: #fff;
  background: none;
}
.home.section-four.grid-container.fluid {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
@media print, screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid {
    padding: 15rem 0 5rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-four.grid-container.fluid {
    padding: 10rem 0 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid {
    padding: 5rem 2rem;
  }
}
.home.section-four.grid-container.fluid .intro {
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .intro {
    padding-bottom: 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .intro {
    text-align: left;
  }
}
.home.section-four.grid-container.fluid .buttons {
  position: relative;
}
.home.section-four.grid-container.fluid .buttons .arrow-wrapper {
  position: absolute;
  right: 0;
  top: -5rem;
  width: 20rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .buttons .arrow-wrapper {
    width: 15rem;
    top: -4rem;
  }
}
.home.section-four.grid-container.fluid .buttons .arrow-wrapper .glide__arrows button.glide__arrow {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #0F2D51;
  border: none;
}
@media print, screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .categories {
    padding-left: 25rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-four.grid-container.fluid .categories {
    padding-left: 5rem;
  }
}
.home.section-four.grid-container.fluid .categories .slide-item {
  height: auto;
  position: relative;
  background: #fff;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .categories .slide-item {
    padding: 0;
  }
}
.home.section-four.grid-container.fluid .categories .slide-item.inactive {
  opacity: 0.5;
}
.home.section-four.grid-container.fluid .categories .slide-item .inner {
  position: relative;
  overflow: hidden;
  white-space: normal;
  width: 100%;
  height: 40rem;
  display: block;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .categories .slide-item .inner {
    height: 30rem;
  }
}
.home.section-four.grid-container.fluid .categories .slide-item .inner img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-four.grid-container.fluid .categories .slide-item .inner .mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.6)), color-stop(154%, rgba(255, 255, 255, 0)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 154%);
  opacity: 1;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-four.grid-container.fluid .categories .slide-item .inner .text {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  border-top: 1px solid #fff;
  padding: 2.5rem 3rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .categories .slide-item .inner .text {
    width: 100%;
    padding: 1rem 2rem;
  }
}
.home.section-four.grid-container.fluid .categories .slide-item .inner .text h4 {
  color: #fff;
  text-align: center;
  font-family: "korolev-rounded", sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0;
}
.home.section-four.grid-container.fluid .categories .slide-item .inner .text .button1 {
  background: none;
  color: #0F2D51;
  border-color: #0F2D51;
  margin-top: 2rem;
}
.home.section-four.grid-container.fluid .categories .slide-item .inner .text .button1:hover {
  background: #0F2D51;
  color: #fff;
}
.home.section-four.grid-container.fluid .categories .slide-item .inner:hover .mask {
  opacity: 0;
}
.home.section-four.grid-container.fluid .read-more {
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .read-more {
    padding: 15rem 5rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-four.grid-container.fluid .read-more {
    padding: 5rem 0;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .read-more {
    padding: 2rem 0;
  }
}
.home.section-four.grid-container.fluid .read-more .button1 {
  margin: 0 2rem;
  display: inline-block;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .read-more .button1 {
    margin: 1.5rem 0;
  }
}
.home.section-four.grid-container.fluid .read-more .button1:nth-child(2) {
  background: #fff;
  color: #0F2D51;
}
.home.section-four.grid-container.fluid .read-more .button1:nth-child(2):hover {
  background: #0F2D51;
  color: #fff;
}
.home.section-five.grid-container.fluid {
  background: #F7F7F8;
}
@media print, screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid {
    padding: 20rem 5rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-five.grid-container.fluid {
    padding: 10rem 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid {
    padding: 5rem 2rem;
  }
}
.home.section-five.grid-container.fluid .newsletter .title {
  text-align: center;
  margin-bottom: 5rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid .newsletter .title {
    padding: 3rem;
  }
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form {
  text-align: center;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #0F2D51;
  border-bottom: 1px solid #0F2D51;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form label svg {
  margin-right: 2rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid .newsletter #newsletter_form label svg {
    margin-right: 2rem;
  }
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form label input {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  font-size: 2.2rem;
  color: #0F2D51;
  background: none;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid .newsletter #newsletter_form label input {
    font-size: 1.8rem;
  }
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #0F2D51;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #0F2D51;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form :-ms-input-placeholder {
  /* IE 10+ */
  color: #0F2D51;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form :-moz-placeholder {
  /* Firefox 18- */
  color: #0F2D51;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .is-invalid-label ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ff495b;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .is-invalid-label ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ff495b;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .is-invalid-label :-ms-input-placeholder {
  /* IE 10+ */
  color: #ff495b;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .is-invalid-label :-moz-placeholder {
  /* Firefox 18- */
  color: #ff495b;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .loading {
  background: url("/loading.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 2rem;
  height: 2rem;
  display: none;
  margin-left: 2rem;
  margin-bottom: -0.5rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid .newsletter #newsletter_form .loading {
    width: 2rem;
    height: 2rem;
    margin-left: 0;
  }
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .help-text {
  font-family: "korolev", sans-serif;
  color: #0F2D51;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .callout.alert {
  background: none;
  border: none;
  padding: 1rem 0 0;
  margin: 0;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .callout.alert p {
  color: #0F2D51;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: "korolev", sans-serif;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .button1 {
  cursor: pointer;
}
.home.section-five.grid-container.fluid .newsletter #newsletter_form .button1:disabled {
  color: #ADB6B5;
  border-color: #ADB6B5;
  cursor: not-allowed;
}
.home.section-five.grid-container.fluid .newsletter .successMessage {
  display: none;
  text-align: center;
}
.home.section-five.grid-container.fluid .newsletter .successMessage h3 {
  font-family: "korolev-rounded", sans-serif;
}